<template>
  <div class="legal-box" v-if="page">
    <div class="legal-container">
      <h1 class="legal-title">{{ $rt(page.title) }}</h1>
      <h4 v-if="page.subheader != null" class="legal-subheader">{{ $rt(page.subheader) }}</h4>
      <p v-if="page.paragraph != null" >{{ $rt(page.paragraph) }}</p>
      <div v-for="item, index in page.content" :key="index" >
        <div v-if="item.isList">
          <h4 v-if="item.subtitle != null" class="legal-item-subtitle">{{ $rt(item.subtitle) }}</h4>
          <div v-for="element, index in item.sections" :key="index">
            <p v-if="element.description != null">{{ $rt(element.description) }}</p>
            <li v-if="element.points != null" v-for="point, index in element.points" :key="index">{{ $rt(point) }}</li>
          </div>
        </div>
        <div v-if="item.isList === false">
          <h4 v-if="item.subtitle != null" class="legal-item-subtitle">{{ $rt(item.subtitle) }}</h4>
          <p v-for="element, index in item.paragraphs" :key="index"  class="legal-description">{{ $rt(element) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';

export default {
  data () {
    return {
      selectedNav: 0,
      LegalData: null
    };
  },
  mounted () {
/*    const id = '#nav' + this.selectedNav;
    gsap.to(id, {
      textDecoration: 'underline'
    });*/
    const legal = this.$tm('legal');
    const route = useRoute();
    this.LegalData = legal[route.params.id];

  },
  methods: {
    handleSelection (index) {
      if (this.selectedNav != index) {
        const id = '#nav' + index;
        const prevId = '#nav' + this.selectedNav;
        gsap.to(id, {
          textDecoration: 'underline'
        });
        gsap.to(prevId, {
          textDecoration: 'none'
        });
        this.selectedNav = index;
      }
    },
  },
  computed: {
    page () {
      return this.LegalData;
    },
    isListCheck () {
      const route = useRoute();
      if (route.params.id === 'boom_irrigation') {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>

hr {
  width: 100%;
}

.legal-title {
  font-weight: 900;
  font-size: 50px;
  margin-top: 60px;
  margin-bottom: 25px;
}

.legal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
  padding-bottom: 50px;
}

.legal-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

.legal-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.legal-subheader {
  margin: 0 0 30px;
  font-size: 20px;
  font-weight: 900;
}

.legal-item-subtitle {
  margin: 0px 0 5px;
}

.legal-description {
  margin-bottom: 20px;
}

@media only screen and (max-width: 1200px) {

}
@media only screen and (max-width: 600px) {
  .legal-title {
    font-size: 35px;
  }
}


</style>